import React from "react";
import Link from "components/Common/Link";
import ConfigForm from "components/Forms/ConfigForm";
import Layout from "layouts/EmptyLayout";
import { redirect } from "libs/utils";

const form = {
  name: "register",
  submitName: "無料で会員登録",
  action: "/auth/register",
  className: "grid grid-cols-1 lg:grid-cols-2 gap-4",
  showReset: true,
  errorMessage: "エラーが発生しました",
  onSuccess: () => {
    redirect("/auth/login");
  },
  fields: [
    {
      type: "input",
      subType: "email",
      name: "email",
      label: "メールアドレス",
      placeholder: "メールアドレス",
      rules: {
        required: true,
        minLength: { value: 5, message: "メールが正しくありません" },
      },
    },
    {
      type: "input",
      subType: "string",
      name: "username",
      label: "ユーザネーム",
      placeholder: "ユーザネーム",
      defaultValue: "thang",
      rules: {
        required: true,
        minLength: {
          value: 5,
          message: "ユーザネームは５桁以上を入力してください",
        },
      },
    },
    {
      type: "input",
      subType: "string",
      name: "first_name",
      label: "名",
      placeholder: "名",
      rules: {
        required: true,
      },
    },
    {
      type: "input",
      subType: "string",
      name: "last_name",
      label: "姓",
      placeholder: "姓",
      rules: {
        required: true,
      },
    },
    {
      type: "input",
      subType: "password",
      name: "password",
      label: "パスワード",
      placeholder: "",
      rules: {
        required: true,
        minLength: {
          value: 8,
          message: "パスワードは８桁以上を入力してください",
        },
      },
    },
    {
      type: "input",
      subType: "password",
      name: "confirm_password",
      label: "パスワード確認",
      placeholder: "",
      rules: {
        required: true,
        minLength: {
          value: 8,
          message: "パスワードは８桁以上を入力してください",
        },
      },
    },
    {
      type: "checkbox",
      subType: "checkbox",
      name: "policy_agree",
      label: "個人情報の取り扱い・プライバシーポリシー・利用規約 に同意",
      rules: {
        required: true,
      },
    },
  ],
};

export default function Register() {
  return (
    <>
      <Layout title="会員登録">
        <h1 className="w-full flex m-6 text-4xl justify-center">会員登録</h1>
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-8/12 px-2 sm:px-4">
              <div className="min-w-0 break-words w-full px-4 py-10 shadow-lg rounded-lg bg-background-300 border-0">
                <div className="">
                  <ConfigForm {...form} />
                </div>
              </div>
              <div className="flex flex-wrap mt-6 relative">
                <div className="w-1/2">
                  <Link href="/auth/register">
                    パスワードを忘れた方はこちら
                  </Link>
                </div>
                <div className="w-1/2 text-right">
                  <Link href="/auth/login">すでに登録済みの方はこちら</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
